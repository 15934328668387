<template>
  <div class="proposal__wrap">
    <div class="section shadow filter__wrap">
      <div>
        <el-radio-group v-model="table.params.type" @change="handleTabs">
          <el-radio-button :label="1">用户列表</el-radio-button>
          <el-radio-button :label="2">冻结用户</el-radio-button>
        </el-radio-group>
      </div>
      <div class="flex">
        <el-form ref="elFormDom" inline :model="table.params">
          <el-form-item label="完善简历" prop="perfect_resume">
            <el-select v-model="table.params.perfect_resume" clearable @change="getTable">
              <el-option label="已完善简历" :value="1"></el-option>
              <el-option label="未完善简历" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="keyword">
            <el-input clearable v-model="table.params.keyword" placeholder="请输入关键字"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询 </el-button>
          </el-form-item>
        </el-form>
      </div>
      
    </div>
    <!-- 表格 -->
    <div class="table__wrap">
      <VTable
        has-pagionation
        title=''
        addText='添加'
        :field="field"
        :loading="table.loading"
        :data="table.data"
        :page='table.params.page'
        :pageSize='table.params.count'
        :total='table.total'
        :tree-props="{children: 'children', hasChildren: 'has_child'}"
        :hasPagionation="true"
        :default-expand-all="true"
        :showOverflowTooltip="true"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      >
        <template v-slot:sex="{row}">
          <span>{{row.sex === 0 ? '未填' : (row.sex === 1 ? '男' : '女')}}</span>
        </template>
        <template v-slot:action="{row}">
          <!-- <el-button v-if="row.perfect == 1" type="text" size="mini" @click="pushPage(row, 1)">查看简历</el-button>  -->
          <el-button v-if="row.perfect == 1" type="text" size="mini" @click="showEdit(row)">查看简历</el-button> 
          <el-button type="text" size="mini" @click="pushPage(row, 2)">操作记录</el-button>
          <el-button v-if="row.is_freeze === 1" type="text" size="mini" icon="el-icon-warning-outline" class="delBtn" @click="handleFreeze(row, 1)">冻结</el-button> 
          <el-button v-if="row.is_freeze === 2" type="text" size="mini" icon="el-icon-circle-check" @click="handleFreeze(row, 2)">解冻</el-button> 
        </template>
      </VTable> 
    </div>

    <detail ref="detail"></detail>

    <!-- 查看简历 -->
    <edit ref="edit"></edit>
    
  </div>
</template>

<script>
import VTable from '@/components/VTable'
import Detail from './components/Detail.vue'
import Edit from './components/Edit.vue'
import { mixinTable } from '@/mixins/table.js'
export default {
  name: 'UserList',
  mixins:[mixinTable],
  components: {
    VTable,
    Detail,
    Edit,
  },
  data() {
    return {
      field: [
        { name: "name", label: "用户名", hidden: false },
        { name: "account", label: "手机号", width: "120", hidden: false },
        { name: "sex", label: "性别", width: "60", hidden: false },
        { name: "school_name", label: "院校", hidden: false },
        { name: "education", label: "学历", hidden: false },
        { name: "faculty_name", label: "专业", hidden: false },
        { name: "agent_name", label: "来源", hidden: false },
        { name: "create_time", label: "注册日期", width: "160", hidden: false },
        { name: "action", label: "操作", fixed:"right", width: "200", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          keyword: '',
          type: 1, // 类型 1:正常 2:冻结
          perfect_resume: 1, // 是否完善简历
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      this.$http.get('/admin/user/list',  {params: this.table.params}).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 切换状态
    handleTabs() {
      this.table.params.page = 1;
      this.table.data = [];
      this.getTable();
    },
    // 查看
    showDetail(row) {
      let dom = this.$refs.detail
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    
    //  1冻结、2解冻
    handleFreeze(row, type) {
      let str = type === 1 ? '冻结' : '解冻'
      let _params = {
        id: row.id,
        type: type 
      }
      this.$confirm(`请确认是否${str}该数据？`, '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消'
      }).then(() =>{
        this.$http.post('admin/user/operation', _params).then(res => {
          if(res.code === 1) {
            this.$message.success('操作成功！')
            this.getTable();
          } else {
            this.$message.error(res.msg)
          }
        })
      }).catch(() => {})
    },
    // 操作记录 type:1简历、2.操作记录  perfect=1的时候才有简历
    pushPage(row,type) {
      if(row.perfect != 1 && type ===1) {
        this.$message.warning("该用户未完善简历")
        return
      } else  {
        sessionStorage.setItem('userId', row.id)
        let _url = type === 1 ? '/userInfo' : '/recordList'
        this.$router.push(_url)
      }
    },
    // 查看简历
    showEdit(row) {
      let dom = this.$refs.edit;
      dom.toggle(true);
      dom.getUserInfo(row);
      dom = null;
    }
  }
}
</script>

<style scoped lang="scss">
  .flex {
    display: flex;
  }
  .proposal__wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px;
    position: relative;

    .filter__wrap {
      padding: 10px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
    }

    .table__wrap {
      flex: 1;
      padding: 0;
      box-sizing: border-box;
    }
  }
</style>