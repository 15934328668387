<template>
  <!-- 简历 -->
  <div class="detail__wrap" v-show="visible">
    <el-breadcrumb separator-class="el-icon-arrow-right" class="crumbs__wrap">
      <el-breadcrumb-item :to="{ path: '/user' }">用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>简历</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 表格 -->
    <div class="section shadow jianli">
      <div>
        <el-button class="backBtn" plain icon="el-icon-d-arrow-left" @click="toggle(false)" size="mini">返回</el-button>
      </div>
      <div class="main">
        <div class="section">
          <div class="flex">
            <el-image
              class="picture"
              style="width: 100px; height: 100px;border-radius:5px"
              :src="userInfo.avatar_str"
              fit="cover">
                <div slot="error" class="image-slot">
                  <img :src="require('@/assets/img/default1.png')" style="width: 100px; height: 100px;border-radius:5px" />
                </div>
              </el-image>
            <div style="display: flex;justify-content: space-between; flex: 1;">
              <div class="user-info">
                <div class="userName fontB">{{userInfo.name}}</div>
                <div class="degree">
                  <span>{{getAge(userInfo.birthday)}}岁</span>
                  <span>{{userInfo.sex === 0 ? '未填' : (userInfo.sex === 1 ? '男' : '女') }}</span>
                  <span>{{userInfo.city_str || '无'}}</span>
                  <span>{{userInfo.faculty_id_str || '无'}}</span>
                  <span>{{userInfo.education_str || '无'}}</span>
                </div>
                <div>
                  <!-- 工作状态：<span style="color:#30C3B1">{{userInfo.work_suffer.type ===1 ? '全职' : '实习'}}</span> -->
                </div>
              </div>
              <p class="date">更新时间：{{$moment(userInfo.update_time).format("yyyy.MM.DD")}}</p>
            </div>
          </div>
          <p>手机号码：{{userInfo.mobile || '无'}}</p>
          <p>电子邮箱：{{userInfo.email || '无'}}</p>
        </div>
        <div class="section mt50">
          <p class="title fontB">自我评价</p>
          <p class="text">{{userInfo.evaluate || '无'}}</p>
        </div>
        <div class="section mt50">
          <p class="title fontB">求职意向</p>
          <p><span>工作地点：</span><span class="fs16">{{userInfo.intention_city_str}}</span></p>
          <p><span>工作岗位：</span><span class="fs16">{{userInfo.intention_job_str}}</span></p>
          <p><span>期望薪资：</span><span class="fs16">{{userInfo.intention_salary_str}}</span></p>
          <p><span>工作类型：</span><span class="fs16">{{intention_type[userInfo.intention_type]}}</span></p>
          <p><span>行业类别：</span><span class="fs16">{{userInfo.intention_category_str}}</span></p>
        </div>
        <div class="section mt50">
          <p class="title fontB">工作/实习经历</p>
          <template v-if="userInfo.work_suffer.length > 0">
            <div v-for="(item,index) in userInfo.work_suffer" :key="index" class="work_suffer">
              <div class="">
                <p class="fs16">{{item.company_name}}</p>
                <p style="font-size:12px;">{{item.category_str}}</p>
                <p v-if="item.end_time">{{item.start_time}} - {{item.end_time}}</p>
              </div>
              <p class="fs16">{{item.job_name}} <span style="margin-left:50px">{{toStr({ 0:'未填',1:'全职',2:'兼职'},item.type)}}</span> <span style="margin-left:50px;">{{item.salary_str}}</span></p>
              <p>{{item.content}}</p>
            </div>
          </template>
          <template v-else>
            无
          </template>
        </div>
        <div class="section mt50">
          <p class="title fontB">在校经历</p>
          <p>{{userInfo.school_suffer || '无'}}</p>
        </div>
        <div class="section mt50">
          <p class="title fontB">教育经历</p>
          <div>
            <p class="fs16">{{userInfo.school_id_str}}</p>
            <p v-if="userInfo.school_start">{{userInfo.school_start}} - {{userInfo.school_end}}</p>
            <p class="fs16" v-if="userInfo.education_str">{{userInfo.education_str}} I {{userInfo.major_id_str}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserInfo',
  data() {
    return {
      visible: false,
      userInfo: {
        work_suffer: {},
      },
      intention_type:{
        0: '未填',
        1: '全职',
        2: '实习'
      }
    }
  },
  // created() {
  //   this.getUserInfo();
  // },
  methods: {
    getUserInfo(row) {
      this.$http.get('/admin/user/info', { params: {id: row.id} }).then(res => {
        if(res.code === 1) {
           this.userInfo = res.data;
        } else {
          this.$message.error(res.msg);
        }
      })
    },
    reset(done) {
      done && done()
    },
    toggle(show) {
      this.visible = show
      !show && this.reset()
    },
    toStr(data, key) {
      return data[key]
    },
    // 根据出生年月结算年龄
    getAge(strBirthday){
      if(!!strBirthday) {
        var returnAge;
        var strBirthdayArr=strBirthday.split("-");
        var birthYear = strBirthdayArr[0];
        var birthMonth = strBirthdayArr[1];
        var birthDay = strBirthdayArr[2];
      
        var d = new Date();
        var nowYear = d.getFullYear();
        var nowMonth = d.getMonth() + 1;
        var nowDay = d.getDate();
      
        if(nowYear == birthYear){
          returnAge = 0;//同年 则为0岁
        }
        else{
          var ageDiff = nowYear - birthYear ; //年之差
          if(ageDiff > 0){
            if(nowMonth == birthMonth) {
              var dayDiff = nowDay - birthDay;//日之差
              if(dayDiff < 0){
                returnAge = ageDiff - 1;
              }else{
                returnAge = ageDiff ;
              }
            }else{
              var monthDiff = nowMonth - birthMonth;//月之差
              if(monthDiff < 0){
                returnAge = ageDiff - 1;
              }else{
                returnAge = ageDiff ;
              }
            }
          }
          else
          {
            returnAge = -1;//出生日期不能大于今天
          }
        }
        return returnAge;
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .detail__wrap {
    background-color: #f5f5f5;
    padding: 20px;
    z-index: 999 !important;
    overflow-y: scroll;
    @include position($t: 0, $r: 0, $b: 0, $l: 0);

    .crumbs__wrap{
      margin-bottom: 20px;
    }

    .main {
      width: 800px;
      background-color: #fff;
      padding: 20px;
    }
  }

  .flex {
    display: flex;
  }
  .mt50 {
    margin-top: 50px;
  }
  .fs16{
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }

  .main {
    width: 800px;
    background-color: #fff;
    padding: 20px;
  }
  .jianli {
    background-color: #fff;
    padding: 20px !important;
    color: #656565;

    .text {
      width: 60%;
      line-height: 30px;
    }
    .title {
      background: url('~@/assets/img/points.png');
      background-repeat: no-repeat;
      background-position-y: 6px;
      padding-left: 30px;
      height: 30px;
      line-height: 30px;
    }
    .picture {
      margin-right: 15px;
    }
    .fontB {
      font-size: 18px;
      font-weight: bold;
      color: #333;
    }
    .section {
      .user-info {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        position: relative;
        
        .userName {
          font-weight: bold;
        }
        .degree span {
          margin-right: 16px;
        }
        .degree span:not(:first-child)::before {
          position: absolute;
          height: 15px;
          width: 2px;
          background-color: #656565;
          margin-top: 2px;
          margin-left: -8px;
          content: "";
        }
      }
      .date {
        height: 30px;
        line-height: 30px;
        background: #30c3b1;
        color: #fff;
        padding: 5px 10px;
        border-radius: 20px 0 0 20px;
        position: absolute;
        right: 20px;

      }
    }
    .work_suffer {
      margin-top:30px;
      border-top:solid 1px #eee;
    }
    .work_suffer:first-child {
      border-top:none;
    }
  }
</style>