<template>
  <el-dialog
    append-to-body
    width="800px"
    title="查看"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      v-loading="loading"
      element-loading-text="加载中…"
      ref='elFormDom'
      label-width='110px'
      label-position='right'
      :model='form.data'
    >
      <template v-if="this.reportType === 1">
        <el-form-item prop="name" label="用户名">
          <el-input :readonly="true" v-model="form.data.name"></el-input>
        </el-form-item>
        <el-form-item prop="phone" label="手机号码">
          <el-input :readonly="true" v-model="form.data.phone"></el-input>
        </el-form-item>
        <el-form-item prop="job_name" label="举报岗位">
          <el-input :readonly="true" v-model="form.data.job_name"></el-input>
        </el-form-item>
        <el-form-item prop="company_name" label="举报企业">
          <el-input  :readonly="true" v-model="form.data.company_name"></el-input>
        </el-form-item>
      </template>
      <template v-if="this.reportType === 2">
        <el-form-item prop="company_name" label="企业名称">
          <el-input :readonly="true" v-model="form.data.name"></el-input>
        </el-form-item>
        <el-form-item prop="phone" label="登录账号">
          <el-input :readonly="true" v-model="form.data.phone"></el-input>
        </el-form-item>
        <el-form-item prop="name" label="被举报人">
          <el-input :readonly="true" v-model="form.data.name"></el-input>
        </el-form-item>
        <el-form-item prop="phone" label="被举报人手机号">
          <el-input  :readonly="true" v-model="form.data.phone"></el-input>
        </el-form-item>
      </template>

      <el-form-item prop="content" label="举报信息">
        <el-input
          :readonly="true"
          type="textarea"
          v-model="form.data.content"
          :autosize="{ minRows: 2, maxRows: 4}"
          show-word-limit
        >
        </el-input>
      </el-form-item>
      <el-form-item prop="img" label="举报图片">
        <template v-if="form.data.image.length > 0">
          <el-image
            v-for="item in form.data.image"
            :key="item"
            style="width: 100px; height: 100px;margin-right:5px"
            :src="item" 
            :preview-src-list="form.data.image">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </template>
        <template v-else>无举报图片</template>
      </el-form-item>
      <el-form-item prop="create_time" label="举报时间片">
        <el-input :readonly="true" v-model="form.data.create_time"></el-input>
      </el-form-item>

    </el-form>
  </el-dialog>
</template>
<script>
  export default {
    name: 'ReportDetail',
    data() {
      return {
        visible: false,
        loading: false,
        reportType: 1,
        form: {
          data: {
            image: [],
          }
        },
      }
    },
    created() {
      this.getDetail();
    },
    methods: {
      getDetail(row) {
        if(!!row) {
          this.reportType = row.type;
          this.$http.get('/admin/report/info', {params:{id: row.id}}).then(res => {
            this.form.data = res.data
          })
        }
      },
      reset(done) {
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
    }
  }
</script>
